import { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';

import { ReactComponent as IconUSDC } from 'Components/ds/assets/icon-usdc.svg';
import { ReactComponent as IconUSDT } from 'Components/ds/assets/icon-usdt.svg';
import { ReactComponent as IconAAVE } from 'Components/ds/assets/icon-aave.svg';
import { ReactComponent as IconWETH } from 'Components/ds/assets/icon-weth.svg';
import { ReactComponent as IconLINK } from 'Components/ds/assets/icon-link.svg';
import { ReactComponent as IconDAI } from 'Components/ds/assets/icon-dai.svg';
import { ReactComponent as IconWBTC } from 'Components/ds/assets/icon-wbtc.svg';
import { ReactComponent as IconETH } from 'Components/ds/assets/icon-eth.svg';
import { ReactComponent as IconBTC } from 'Components/ds/assets/icon-btc.svg';
import { ReactComponent as IconGBTC } from 'Components/ds/assets/icon-gbtc.svg';
import { ReactComponent as IconLBTC } from 'Components/ds/assets/icon-lbtc.svg';
import { ReactComponent as IconSOL } from 'Components/ds/assets/icon-sol.svg';
import { ReactComponent as IconWstETH } from 'Components/ds/assets/icon-wstEth.svg';
import { ReactComponent as IconPTsUSDe } from 'Components/ds/assets/icon-pt-susde.svg';
import { ReactComponent as IconMPL } from 'Components/ds/assets/icon-mpl.svg';
import { ReactComponent as IconXMPL } from 'Components/ds/assets/icon-xmpl.svg';
import { ReactComponent as IconADA } from 'Components/ds/assets/icon-ada.svg';
import { ReactComponent as IconAVAX } from 'Components/ds/assets/icon-avax.svg';
import { ReactComponent as IconBNB } from 'Components/ds/assets/icon-bnb.svg';
import { ReactComponent as IconFIL } from 'Components/ds/assets/icon-fil.svg';
import { ReactComponent as IconFTM } from 'Components/ds/assets/icon-ftm.svg';
import { ReactComponent as IconINJ } from 'Components/ds/assets/icon-inj.svg';
import { ReactComponent as IconXRP } from 'Components/ds/assets/icon-xrp.svg';
import { ReactComponent as IconORCALPUSDC } from 'Components/ds/assets/icon-orca-lp-usdc.svg';
import { ReactComponent as IconTBills } from 'Components/ds/assets/icon-t-bills.svg';
import { ReactComponent as IconSDAI } from 'Components/ds/assets/icon-sdai.svg';
import { ReactComponent as IconSUSDS } from 'Components/ds/assets/icon-susds.svg';
import { ReactComponent as IconUSTB } from 'Components/ds/assets/icon-ustb.svg';

const AssetSymbols = [
  'USDC',
  'USDCk1',
  'USDC-MPL',
  'USDT',
  'AAVE',
  'WETH',
  'kycUSDC',
  'kycWETH',
  'LINK',
  'DAI',
  'WBTC',
  'ETH',
  'BTC',
  'WSTETH',
  'PT_sUSDE',
  'GBTC',
  'LBTC',
  'SOL',
  'Legacy MPL',
  'MPL',
  'xMPL',
  'ADA',
  'AVAX',
  'BNB',
  'FIL',
  'FTM',
  'INJ',
  'XRP',
  'OrcaLP_PYUSDC',
  'US T-Bill',
  'USTB',
  'sDAI',
  'sUSDS',
] as const;
export type AssetSymbol = (typeof AssetSymbols)[number];

const isAssetSymbol = (symbol: string): symbol is AssetSymbol => AssetSymbols.includes(symbol as AssetSymbol);

export interface CoinIconProps {
  asset: AssetSymbol | string;
  size?: 'sm' | 'md' | 'mdLg' | 'lg' | 'xl';
  component?: 'div' | 'span';
}

const styles = {
  main: {
    borderRadius: '50%',
    filter: 'drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.10)) drop-shadow(0px 4px 6px rgba(16, 24, 40, 0.10))',
  },
  sm: {
    width: '16px',
    height: '16px',
  },
  md: {
    width: '20px',
    height: '20px',
  },
  mdLg: {
    width: '22px',
    height: '22px',
  },
  lg: {
    width: '32px',
    height: '32px',
  },
  xl: {
    width: '57px',
    height: '57px',
  },
};

const assetIcon: { [assetSymbol in AssetSymbol]: ReactNode } = {
  USDC: <IconUSDC width='100%' height='100%' />,
  USDCk1: <IconUSDC width='100%' height='100%' />,
  'USDC-MPL': <IconUSDC width='100%' height='100%' />,
  USDT: <IconUSDT width='100%' height='100%' />,
  AAVE: <IconAAVE width='100%' height='100%' />,
  WETH: <IconWETH width='100%' height='100%' />,
  kycUSDC: <IconUSDC width='100%' height='100%' />,
  kycWETH: <IconWETH width='100%' height='100%' />,
  LINK: <IconLINK width='100%' height='100%' />,
  DAI: <IconDAI width='100%' height='100%' />,
  WBTC: <IconWBTC width='100%' height='100%' />,
  ETH: <IconETH width='100%' height='100%' />,
  BTC: <IconBTC width='100%' height='100%' />,
  WSTETH: <IconWstETH width='100%' height='100%' />,
  PT_sUSDE: <IconPTsUSDe width='100%' height='100%' />,
  GBTC: <IconGBTC width='100%' height='100%' />,
  LBTC: <IconLBTC width='100%' height='100%' />,
  SOL: <IconSOL width='100%' height='100%' />,
  'Legacy MPL': <IconMPL width='100%' height='100%' fill='#8E8E9B' />,
  MPL: <IconMPL width='100%' height='100%' fill='#FF7900' />,
  xMPL: <IconXMPL width='100%' height='100%' fill='#FF7900' />,
  ADA: <IconADA width='100%' height='100%' />,
  AVAX: <IconAVAX width='100%' height='100%' />,
  BNB: <IconBNB width='100%' height='100%' />,
  FIL: <IconFIL width='100%' height='100%' />,
  FTM: <IconFTM width='100%' height='100%' />,
  INJ: <IconINJ width='100%' height='100%' />,
  XRP: <IconXRP width='100%' height='100%' />,
  OrcaLP_PYUSDC: <IconORCALPUSDC width='100%' height='100%' />,
  'US T-Bill': <IconTBills width='100%' height='100%' />,
  USTB: <IconUSTB width='100%' height='100%' />,
  sDAI: <IconSDAI width='100%' height='100%' />,
  sUSDS: <IconSUSDS width='100%' height='100%' />,
};

const CoinIcon: FC<CoinIconProps> = ({ asset, size = 'sm', component = 'div' }: CoinIconProps) => {
  if (!isAssetSymbol(asset)) return null;

  return (
    <Box sx={{ ...styles[size], ...styles.main }} component={component}>
      {assetIcon[asset]}
    </Box>
  );
};

export default CoinIcon;
